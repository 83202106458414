<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <v-dialog persistent v-model="dialog" max-width="1200px">
                <template v-slot:activator="{ on }">
                  <div class="d-flex">
                    <v-btn
                      color="primary"
                      dark
                      class="ml-auto ma-3 mr-1"
                      @click="refreshData"
                    >
                      <i class="flaticon2-refresh-1 text-white"></i>
                    </v-btn>
                    <v-btn
                      v-if="hasPermission('pages.create')"
                      color="primary"
                      dark
                      class="ma-3 ml-0"
                      v-on="on"
                    >
                      <i class="flaticon2-plus text-white"></i>
                    </v-btn>
                  </div>
                </template>
                <v-card>
                  <i class="fas fa-times custom-times" @click="closeDialog"></i>
                  <v-card-title>
                    <span v-if="editedItem.id">Edit {{ editedItem.name }}</span>
                    <span v-else>Create Page</span>
                  </v-card-title>
                  <v-card-text>
                    <v-tabs background-color="deep-purple accent-4" left dark>
                      <v-tabs-slider></v-tabs-slider>
                      <v-tab>Details</v-tab>
                      <v-tab>Media</v-tab>

                      <v-tab-item>
                        <v-card flat>
                          <v-card-text>
                            <v-form
                              ref="form"
                              v-model="validationRules.valid"
                              lazy-validation
                              id="page_form"
                            >
                              <v-row>
                                <v-col cols="12" sm="12">
                                  <v-select
                                    clearable
                                    :items="menusFilter"
                                    v-model="editedItem.menu_id"
                                    item-text="title"
                                    item-value="id"
                                    label="Menu"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                    v-model="editedItem.name"
                                    :counter="200"
                                    :rules="validationRules.nameRules"
                                    label="Name*"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                    v-model="editedItem.title"
                                    :counter="200"
                                    :rules="validationRules.nameRules"
                                    label="Title*"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                    v-model="editedItem.slug"
                                    :counter="300"
                                    :rules="validationRules.slugRules"
                                    label="Slug*"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <label>Body*</label>
                                  <editor
                                    :rules="validationRules.nameRules"
                                    ref="et_editor"
                                    v-model="editedItem.body"
                                    :api-key="tinyKey"
                                    :init="editorInit"
                                  />
                                  <br />
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-select
                                    clearable
                                    v-model="editedItem.enabled"
                                    :items="ques"
                                    :rules="validationRules.selectedRules"
                                    item-text="title"
                                    item-value="value"
                                    label="Enabled*"
                                  >
                                  </v-select>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                    clearable
                                    v-model="editedItem.meta_title"
                                    maxLength="255"
                                    :counter="255"
                                    label="Meta Title"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-textarea
                                    v-model="editedItem.meta_keywords"
                                    label="Meta Keywords"
                                    maxLength="255"
                                    :counter="255"
                                    clearable
                                  ></v-textarea>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-textarea
                                    v-model="editedItem.meta_description"
                                    label="Meta Description"
                                    maxLength="255"
                                    :counter="255"
                                    clearable
                                  ></v-textarea>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <ModuleMedia
                          moduleType="image"
                          moduleOf="page"
                          moduleMultiple="false"
                          :moduleImages="moduleImages"
                          :moduleImagesOld="moduleImagesOld"
                        ></ModuleMedia>
                      </v-tab-item>
                    </v-tabs>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog"
                      >Cancel</v-btn
                    >
                    <v-btn
                      :disabled="!validationRules.valid"
                      color="blue darken-1"
                      text
                      @click="saveItem(editedItem)"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                  <v-col cols="12">
                    <div
                      v-if="errors != undefined && errors.length >= 0"
                      role="alert"
                      v-bind:class="{ show: errors.length }"
                      class="alert fade alert-danger"
                    >
                      <div
                        class="alert-text"
                        v-for="(error, i) in errors"
                        :key="i"
                      >
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <hr style="margin:0" />
              <v-skeleton-loader
                  :loading="loading"
                  v-if="isLoaded"
                  type="table"
              >
              </v-skeleton-loader>
              <v-card-title>
                Pages ({{ totalRecords }})
                <v-spacer></v-spacer>
                <v-text-field
                  @keydown.enter="searchQueryData"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="items"
                :options.sync="pagination"
                :server-items-length="totalRecords"
                :footer-props="{
                  itemsPerPageOptions: rowsPerPageItems,
                  showFirstLastPage: true
                }"
                :loading="loading"
                v-show="!isLoaded"
                mobile-breakpoint="800"
                class="elevation-0"
              >
                <template v-slot:item.enabled="{ item }">
                  <div class="text-truncate">
                    <p v-if="item.enabled == 0">No</p>
                    <p v-if="item.enabled == 1">Yes</p>
                  </div>
                </template>
                <template v-slot:item.created_at="{ item }">
                  <div class="text-truncate">
                    {{ moment(item.created_at).fromNow() }}
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="text-truncate">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-if="hasPermission('pages.edit')"
                          color="primary"
                          @click="showEditDialog(item)"
                          dark
                          class="mr-2"
                          v-on="on"
                        >
                          <v-icon dark small>fas fa-edit</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-if="hasPermission('pages.delete')"
                          color="red"
                          dark
                          @click="deleteItem(item)"
                          class="mr-2"
                          v-on="on"
                        >
                          <v-icon dark small>fas fa-trash</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
              <!-- this dialog is used for both create and update -->
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ModuleMedia from "@/view/pages/admin/media/ModuleMedia";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  PAGES_LIST,
  CREATE_PAGE,
  UPDATE_PAGE,
  CLEAR_PAGE_ERRORS,
  DELETE_PAGE,
  GET_PAGE_DETAILS
} from "@/core/services/store/pages.module";
// import {bus} from "@/main";
import { SET_CONFIRMATION } from "@/core/services/store/confirmation.module";
import { bus } from "@/main";
import { MENUS_SIMPLE_LIST } from "@/core/services/store/menus.module";
import templates from "@/core/templates/templates.json";

export default {
  name: "pages",
  components: {
    ModuleMedia
  },
  data() {
    return {
      editorInit: {
        branding: false,
        height: 500,
        menubar: true,
        images_dataimg_filter: function(img) {
          return img.hasAttribute("internal-blob");
        },
        images_upload_url: "image",
        automatic_uploads: false,
        templates: templates,
        plugins: [
          "advlist autolink template lists code link image imagetools charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code wordcount fullscreen"
        ],
        toolbar:
          "undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | code | link | image | fullscreen | template"
      },
      draw: 1,
      searchQuery: "",
      loading: true,
      isLoaded: true,
      pagination: {
        mustSort: true,
        multiSort: false,
        page: 1,
        rowsPerPage: 10,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [10, 15, 20, 30, 40, 50],
      columns: [],
      moduleImages: [],
      moduleImagesOld: [],
      headerindex: "",
      headers: [
        {
          text: "Menu",
          value: "menu.title",
          name: "menu.title",
          filterable: true,
          sortable: true
        },
        {
          text: "Name",
          value: "name",
          name: "name",
          filterable: true,
          sortable: true
        },
        {
          text: "Title",
          value: "title",
          name: "title",
          filterable: true,
          sortable: true
        },
        {
          text: "Slug",
          value: "slug",
          name: "slug",
          filterable: true,
          sortable: true
        },
        {
          text: "Enabled",
          value: "enabled",
          name: "enabled",
          filterable: true,
          sortable: true
        },
        {
          text: "Created",
          value: "created_at",
          name: "created_at",
          filterable: true,
          sortable: true
        },
        {
          text: "Action",
          value: "actions",
          name: "name",
          filterable: true,
          sortable: false
        }
      ],
      validationRules: {
        valid: true,
        nameRules: [
          v => !!v || "This field is required",
          v =>
            (v && v.length <= 200) ||
            "Must be less than or equal to 200 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        slugRules: [
          v => !!v || "This field is required",
          v =>
            (v && v.length <= 300) ||
            "Must be less than or equal to 300 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        selectedRules: [v => !!v || "This field is required"]
      },
      items: [],
      menusFilter: [],
      dialog: false,
      show: false,
      editedItem: {},
      ques: [
        { value: "0", title: "No" },
        { value: "1", title: "Yes" }
      ]
    };
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true
    },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_PAGE_ERRORS);
      if (!val) this.editedItem = {};
      if (!val) this.editedItem.body = "";
      if (!val) this.moduleImages = [];
      if (!val) this.moduleImagesOld = [];
    }
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
        "&columns[" +
          i +
          "][data]=" +
          this.headers[i].value +
          "&columns[" +
          i +
          "][name]=" +
          this.headers[i].name +
          "&columns[" +
          i +
          "][searchable]=" +
          this.headers[i].filterable +
          "&columns[" +
          i +
          "][orderable]=" +
          this.headers[i].sortable +
          "&columns[" +
          i +
          "][search][value]=" +
          "&columns[" +
          i +
          "][search][regex]=" +
          false
      );
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Content Management", route: "pages" },
      { title: "Pages" }
    ]);
    bus.$on("moduleImagesUploaded", moduleImages => {
      this.moduleImages = moduleImages.arr;
    });
    bus.$on("moduleImagesUploadedOld", moduleImagesOld => {
      this.moduleImagesOld = moduleImagesOld.arr;
    });
    this.getMenus();
  },
  methods: {
    searchQueryData: function(e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise(resolve => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fecth per page
        params.start =
          params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params)
          .map(key => {
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
            );
          })
          .join("&");
        this.getHeaderIndex(params.sortBy[0]);
        query +=
          "&search[value]=" +
          params.query +
          "&search[regex]=" +
          false +
          "&order[0][column]=" +
          (params.sortBy.length === 0 ? 5 : this.headerindex) +
          "&order[0][dir]=" +
          (params.sortDesc[0] ? "desc" : "asc");
        this.$store
          .dispatch(PAGES_LIST, { q: query, columns: this.columns })
          .then(res => {
            this.draw++;
            let items = res.records.data;
            let total = res.records.recordsFiltered;
            this.loading = !this.loading;
            this.isLoaded = !this.isLoaded;
            resolve({
              items,
              total
            });
          });
      });
    },
    refreshData() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
        this.getMenus();
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    showEditDialog(item) {
      this.$store.dispatch(GET_PAGE_DETAILS, item.id).then(data => {
        let pageImages = [];
        this.editedItem = data.records || {};
        if (data.records.image_public_url !== null) {
          pageImages.push(data.records);
        }
        this.moduleImagesOld = pageImages;
        this.dialog = !this.dialog;
      });
    },
    closeDialog() {
      if (this.moduleImages.length > 0) {
        let mFiles = [];
        let that = this;
        mFiles = this.moduleImages;
        this.$store.dispatch(SET_CONFIRMATION, {
          title: "Are you sure?",
          msg: "All unsaved data will be lost",
          clr: "red",
          callback: function() {
            that.$store
              .dispatch("delete_uploaded_image", {
                files: mFiles,
                type: "modal"
              })
              .then(() => {
                that.moduleImages = [];
                that.dialog = false;
                bus.$emit("moduleImagesDeletedByModal");
              });
          }
        });
      } else {
        this.dialog = !this.dialog;
        // bus.$emit('moduleModalClosed');
      }
    },
    saveItem(item) {
      if (this.$refs.form.validate()) {
        /* this is used for both creating and updating API records
       the default method is POST for creating a new item */
        let method = CREATE_PAGE;
        let id = item.id;
        this.$set(item, "image", this.moduleImages);
        let data = {
          data: item
        };
        if (id) {
          // if the item has an id, we're updating an existing item
          method = UPDATE_PAGE;
          // delete data.data.id;
        }
        this.$store.dispatch(CLEAR_PAGE_ERRORS);
        // save the record
        this.$store.dispatch(method, { slug: id, data: data.data }).then(() => {
          this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
          });
          this.editedItem = {};
          this.dialog = !this.dialog;
          bus.$emit("moduleImagesDeletedByModal");
        });
      }
    },
    deleteItem(item) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?",
        msg: "You want to delete '" + item.name + "'?",
        clr: "red",
        callback: function() {
          let id = item.id;
          this.$store
            .dispatch(DELETE_PAGE, {
              page_id: id
            })
            .then(() => {
              that.loadData().then(response => {
                that.items = response.items;
                that.totalRecords = response.total;
              });
            });
        }
      });
    },
    getMenus() {
      this.$store.dispatch(MENUS_SIMPLE_LIST).then(data => {
        this.menusFilter = data.records.menusList;
      });
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function(ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    }
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.pages.errors
    })
  }
};
</script>
